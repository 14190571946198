import * as yup from 'yup';
import { NNUMBER_REGEX } from '../../../Constants';

export const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/

export const userSchema = yup
.object()
  .shape({
    applicant_type: yup.string(),
    name: yup.string().when(['applicant_type'], (applicant_type, schema) => {
        return applicant_type === 'Individual' ? schema.required("Required") : schema.nullable();
    }),
    surname: yup.string().required("Required"),
    phone_number: yup.string("Required").typeError('Required').required("Required").test('phone_number', 'Please enter a valid phone number', phoneNumber =>  
         phoneNumber && re.test(phoneNumber.trim()) ),
    email: yup.string().required("Required").trim().email("Must be a valid email address"),
    address: yup.string().required("Required"),
    state: yup.string().required("Required").test("state", "Required", (state) => state !== 'default'),
    city: yup.string().required("Required"),
    zipcode: yup.string("Required").required("Required").typeError('Required'),
    add_later: yup.boolean(),
    bypass_clearance:yup.boolean(),
    n_number: yup.string().when(['add_later'], (add_later, schema) => {
        return !add_later ? schema.matches(NNUMBER_REGEX,"Invalid NNumber") : schema;
    })
})
.required();