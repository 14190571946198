import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import dayjs from 'dayjs';
import { OrangeButton } from '../Core/Buttons/OrangeButton';
import { Delete } from '@material-ui/icons';
import { TrashIcon } from './icons';
import { BlueButton } from '../Core/Buttons/BlueButton';
import { AlertContextStore } from '../../context/AlertContext/AlertContext';
import { QuoteContextStore } from "../../context/QuoteContext/QuoteContext";
import { LoadingContextStore } from '../../context/LoadingContext/LoadingContext';
import { SessionContextStore } from "../../context/SessionContext/SessionContext";
import { QUOTE_STATUS } from '../../context/QuoteContext/types';

const CustomPaper = withStyles((theme) => ({
    root: {
        boxShadow: 'none',
        borderRadius: '20px',
        [theme.breakpoints.down('xs')]: {
            borderRadius: '30px 0 20px 20px'
        },
        minHeight: '100px',
        padding: '20px 30px',
        boxShadow: '0 24px 48px 0 rgba(44, 39, 56, 0.04), 0 12px 24px 0 rgba(44, 39, 56, 0.02)',
        marginTop: '40px'
    }
}))(Paper);

const DeleteButton = withStyles(theme => ({
    root: {
        fontFamily: 'Poppins-regular',
        textDecoration: 'underline',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer'
        }
    }
}))(Typography);

const QuoteStatusText = ({ status }) => {
    switch (status) {
        case 'Application':
            return (
                <span className="quote-application">
                    Application in progress
                </span>
            );
        case 'Quoted':
        case 'Approved':
            return <span className="quote-quoted">Quoted</span>;
        case 'Underwriter':
            return (
                <span className="quote-underwriter">
                    In underwriter review
                </span>
            );
        case 'Declined':
            return <span className="quote-declined">Declined</span>;
        case "NotCleared":
            return(<span className="quote-declined"> Not Cleared</span>)
        default:
            return(<span></span>)
    }
};


const QuoteTypeText = ({ type }) => {
    switch (type) {
        case 'Renewal':
            return (
                <span className="quote-application">
                    Renewal
                </span>
            );
        default:
            return(<span></span>)
    }
};

const QuoteCard = ({ quote, deleteQuote }) => {
    const history = useHistory();
    const { isAdmin } = useContext(SessionContextStore);
    const {loadQuote } = useContext(QuoteContextStore);
    const { awaitWithLoading } = useContext(LoadingContextStore);
    const shouldGoToCheckout = quote.status === 'Quoted' || quote.status === 'Approved';
    const { showInfo } = useContext(AlertContextStore);

    const handleDeleteQuote = () => {
        showInfo({
            title: "Deleting quote",
            message: "Are you sure you want to delete this quote?",
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            onConfirm: async () => {
                await deleteQuote(quote.quote_id);
            }
        });
    }

    return (
        <CustomPaper>
            <Grid container justifyContent="space-between" className='quote-card-header'>
                <div className="quote-card-aircraft">
                    Aircraft:{' '}
                    <span>{quote.aircraft}</span>
                </div>
                <div>
                    Date added:{' '}
                    <span>{new Date(quote.date_created).toDateString()}</span>
                </div>
            </Grid>
            <div class="quote-card-empty-divider"/>
            <div className="quote-card-content">
                <Grid container justifyContent="space-between">
                    <div className="quote-card-text">
                        Name: <span>{quote.name}</span>
                    </div>
                    <DeleteButton className="quote-card-text" onClick={handleDeleteQuote}>Delete<TrashIcon/></DeleteButton>
                </Grid>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <div>
                        <div className="quote-card-text">
                            Registration number: {quote.registration_number}
                        </div>
                        <div className="quote-card-text">
                            Status:{' '}
                            <QuoteStatusText status={quote.status}>
                                {quote.status}
                            </QuoteStatusText>
                        </div>
                        {quote.type === 'Renewal' && <div className="quote-card-text">
                            Type: Renewal
                        </div>}
                        <div>
                            {quote.status !== QUOTE_STATUS.APPLICATION && (
                                quote.is_hull_include ? (
                                    <div className="quote-card-text">Coverage: Liability and Physical Damage</div>
                                ) : (
                                    <div className="quote-card-text">Coverage: Liability Only</div>
                                )
                            )}
                        </div>
                    </div>
                    <div>
                        <BlueButton
                            slim
                            className={shouldGoToCheckout ? "quote-goto-checkout" : ""}
                            onClick={async () => {
                                if(quote.type === 'Renewal'){
                                    await awaitWithLoading(loadQuote(quote.quote_id)) 
                                    if(isAdmin){
                                        history.push('/insurance/profile/' + quote.quote_id) 
                                    }
                                    else{
                                        history.push('/edit-renewal')
                                    }
                                }
                                else{
                                    shouldGoToCheckout ? history.push('/insurance/checkout/' + quote.quote_id): history.push('/insurance/profile/' + quote.quote_id)}
                                }}>
                            {shouldGoToCheckout ? 'Continue to purchase' : 'Back to quote'}
                        </BlueButton>
                    </div>
                </Grid>
            </div>
        </CustomPaper>
    );
};

export default QuoteCard;
