import React, { useReducer, createContext } from "react"
import SESSION_ACTIONS, {useActions} from "./actions"
import SweetAlert2 from 'react-sweetalert2';
import { Grid, Typography } from "@material-ui/core";
import { OrangeButton } from "../../components/Core/Buttons/OrangeButton";
import { BlueButton } from '../../components/Core/Buttons/BlueButton';

const defaultState = {
    show: false,
    icon: "",
    title: "",
    message: "",
    onCancel: null,
    onConfirm: null,
    confirmButtonText: "Ok",
    cancelButtonText: "Cancel",
    ignoreCancel: false
}

const reducer = (state, action) => {
    const payload = action.payload;
    switch (action.type) {
    case SESSION_ACTIONS.SHOW_ERROR:
        return {...defaultState, show: true, title: payload.title, message:payload.message, onConfirm:payload.onConfirm, onCancel: payload.onCancel, icon: 'error', ignoreCancel: true, confirmButtonText: "Ok"};
    case SESSION_ACTIONS.SHOW_INFO:
        return {...defaultState, show: true, ...payload, icon: 'info'};
    case SESSION_ACTIONS.SHOW_WARNING:
        return {...defaultState, show: true, title: payload.title, message:payload.message, onConfirm:payload.onConfirm, onCancel: payload.onCancel, icon: 'warning', ignoreCancel: false}
    case SESSION_ACTIONS.SHOW_SUCCESS:
        return {...defaultState, show: true, title: payload.title, message:payload.message, onConfirm:payload.onConfirm, onCancel: payload.onCancel, icon: 'success', ignoreCancel: false}
    case SESSION_ACTIONS.SHOW_SERVER_ERROR:
        return {...defaultState, show: true, title: payload.title, message:payload.message, onConfirm:payload.onConfirm, onCancel: payload.onCancel, icon: 'error', ignoreCancel: true}
    case SESSION_ACTIONS.HIDE:
        if (!state.show) return state;
        return {...state, show: false};
      default: 
        return state;
    }
};

export const AlertContextStore = createContext(defaultState)

const AlertContext = ({children}) => {
    const [state, dispatch] = useReducer(reducer, defaultState)
    const actions = useActions(dispatch);
    const { show, title, message, icon, cancelButtonText, confirmButtonText, ignoreCancel } = state;

    const onConfirm = () => {
        actions.hide();
        if(!state.onConfirm) return;
        state.onConfirm();
    }

    const onCancel = () => {
        actions.hide();
        if(!state.onCancel) return;
        state.onCancel();
    }

    return (<AlertContextStore.Provider value={{ ...actions }}>
            {children}
            <SweetAlert2 show={show} didClose={actions.hide} showConfirmButton={false}>
                <Grid container justifyContent="center">
                    <Typography variant="h2" className="bold blue margin-bottom-basic margin-top-basic">{title}</Typography>
                </Grid>
                <Grid container justifyContent="center">
                    <Typography variant="body3">{message}</Typography>
                </Grid>
                <Grid container justifyContent="center">
                    <OrangeButton className="margin-top-basic" onClick={onConfirm}>{confirmButtonText}</OrangeButton>
                    {!ignoreCancel && <BlueButton className="margin-left-10 margin-top-basic" onClick={onCancel}>{cancelButtonText}</BlueButton> }
                </Grid>
            </SweetAlert2>
        </AlertContextStore.Provider>)   
}

export default AlertContext

